import { PostDetailComponent } from './pages/post-detail/post-detail.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PaginaNotFoundComponent } from './pages/pagina-not-found/pagina-not-found.component';
import { ChatbotComponent } from './pages/chatbot/chatbot.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { provideLottieOptions } from 'ngx-lottie';
import "@lottiefiles/lottie-player";

export function playerFactory() {
  return import("lottie-web");
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    PaginaNotFoundComponent,
    ChatbotComponent,
    CookiesComponent
  ],
  providers: [
    { provide: "googleTagManagerId", useValue: "GTM-WR66X47" },
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
  
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    LazyLoadImageModule
  ]
})
export class AppModule { }
