<div style="min-height: 300px" class="banner-interno">
  <div class="container">
    <div class="titulo-banner centered"></div>
  </div>
</div>
<div class="container">
  <div class="row">
    <header class="d-flex justify-content-between mt-5 align-items-center">
      <div class="d-flex gap-3">
        <a href="/ajuda">
          <svg
            width="30"
            height="30"
            viewBox="0 0 37 30"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              d="M14.8525 0.443129L0 14.9261L14.8525 29.409C14.9867 29.5799 15.1571 29.7209 15.3523 29.8223C15.5474 29.9237 15.7628 29.9833 15.9837 29.997C16.2047 30.0106 16.4261 29.9781 16.633 29.9015C16.8398 29.825 17.0273 29.7062 17.1828 29.5533C17.3382 29.4003 17.458 29.2168 17.5339 29.015C17.6099 28.8133 17.6402 28.598 17.623 28.3839C17.6057 28.1698 17.5413 27.9617 17.4339 27.7739C17.3266 27.586 17.1789 27.4227 17.0008 27.2951L5.88847 16.4254L35.4545 16.4254C35.8644 16.4254 36.2575 16.2674 36.5473 15.9862C36.8372 15.7051 37 15.3237 37 14.9261C37 14.5285 36.8372 14.1471 36.5473 13.8659C36.2575 13.5848 35.8644 13.4268 35.4545 13.4268L5.88847 13.4268L17.0008 2.5571C17.2898 2.27478 17.4513 1.89266 17.4499 1.49481C17.4484 1.09696 17.2841 0.71596 16.9931 0.435629C16.7021 0.1553 16.3082 -0.00139809 15.898 7.62939e-06C15.4879 0.00141335 15.0952 0.160809 14.8062 0.443129H14.8525Z"
              fill="#005FAF"
            />
          </svg>
        </a>
        <a href="/ajuda" class="mt-1" style="color: #005faf">{{ title }}</a>
      </div>
    </header>

    <section class="imagem-click d-flex align-items-center flex-wrap">
      <div class="col-12 col-md-6">
        <img class="maquina-click" src="assets/img/{{ imagemClick }}" alt="" />
      </div>
      <div class="col-12 col-md-6">
        <header class="text-center">
          <h4>Conheça as funcionalidades</h4>
        </header>
        <ul class="mt-4">
          <li class="lista" *ngFor="let m of manual; let myIndex = index">
            <span class="number-click">{{ myIndex + 1 }}</span>
            <span class="text-click" [innerHtml]="m.texto"></span>
          </li>
        </ul>
      </div>
    </section>

    <!-- Faq manual -->
    <section class="faq-manual">
      <div class="accordion" id="accordionExample">
        <div *ngFor="let item of faq; let key = index" class="accordion-item">
          <h2 class="accordion-header" id="p{{ key }}">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              attr.data-bs-target="#c{{ key }}"
              aria-expanded="true"
              attr.aria-controls="c{{ key }}"
            >
              {{ item.pergunta }}
            </button>
          </h2>
          <div
            id="c{{ key }}"
            class="accordion-collapse collapse"
            attr.aria-labelledby="p{{ key }}"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body" [innerHtml]="item.resposta"></div>
          </div>
        </div>
      </div>
      <div class="botao mt-5 text-center">
        <h4>Baixar o manual em versão PDF</h4>
        <a
          class="btn btn-primary"
          href="{{ env.origin }}/midias/{{ src }}.pdf"
          download
          target="_blank"
          >Download</a
        >
      </div>
    </section>
    <!-- About download -->
    <section class="sobre-download">
      <div class="d-flex align-items-center flex-wrap gap-5">
        <div class="col-12 col-md-5 about-download">
          <img class="layout-div" src="assets/img/layout-div.png" alt="" />
          <h4>Tudo que você precisa saber em um único lugar</h4>
          <p>Ainda tem dúvidas de como utilizar sua azulzinha?</p>
          <p>
            Acesse agora mesmo nosso canal no YouTube e confira dicas de como
            realizar estornos, cancelamento de compras, antecipação pelo App e
            muito mais.
          </p>
          <a
            style="
              color: white;
              cursor: pointer;
              text-decoration: underline !important;
            "
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLuodoEzznFlOgDBaZDlFgiRKR7tMFrGwd"
            >Saiba mais</a
          >
        </div>
        <div class="col-12 col-md-5 text-center">
          <h4>Baixe agora o App da azulzinha</h4>
          <div
            class="d-flex mt-5 gap-5 justify-content-center align-items-center"
          >
            <div
              class="d-flex align-items-center gap-3 flex-column col-md-6 donwload-play"
            >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.fiserv.gestao.caixa"
                ><img class="img-fluid" src="assets/img/googlePlay.png" alt=""
              /></a>
              <img
                class="img-fluid"
                src="assets/img/qr-code-play-store.png"
                alt=""
              />
            </div>
            <div
              class="d-flex align-items-center gap-3 flex-column col-md-6 donwload-apple"
            >
              <a
                target="_blank"
                href="https://apps.apple.com/br/app/caixa-pagamentos/id1570685624"
                ><img
                  style="width: 11rem"
                  class="img-fluid"
                  src="assets/img/appStore.svg"
                  alt=""
              /></a>
              <img
                class="img-fluid"
                src="assets/img/qr-code-apple-store.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
