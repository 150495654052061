
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  
  constructor( private router: Router, private gtmService: GoogleTagManagerService) { }

  private modal: any
  private fade: any
  private body: any
  private toggleModal!: Function

  @ViewChild('teste') modalCampo!: HTMLElement | any;
  openMobileMenu = false;
  openSolucoesMenu = false;
  openVendasMenu = false;
  openGestaoMenu = false;
  openServicosMenu = false;
  transactionId = 332950684;

  ngOnInit(): void {
    window.scrollTo(0, 0)
   
    this.onNavigation()  
   
    this.fade = document.querySelector('#fade')
    this.modal = document.querySelector('#modal')
    this.body = document.querySelector('body')
    this.toggleModal = () => {
      this.toggleMobileMenu()
      this.modal.classList.toggle('show')
      this.fade.classList.toggle('show')
    }

  }
  abrirModal(){
    this.toggleModal()
    this.body.classList.add('not-scroll')
  }
  onNavigation(tag: string = '') {

    let gtmtag = {};

    switch (tag) {

      case 'gtm-solicitar':
        gtmtag = {
          event: 'clicou_peca_azulzinha',
          name: 'Peça já a azulzinha',
          transactionId: this.transactionId
        };
        this.gtmService.pushTag(gtmtag);
        break;
      case 'gtm-fale':
        gtmtag = {
          event: 'button-click',
          name: 'Fale-conosco',
          transactionId: this.transactionId
        };
        this.gtmService.pushTag(gtmtag);
        break;
      case 'gtm-tenho':
        gtmtag = {
          event: 'button-click',
          name: 'Tenho-interesse',
          transactionId: this.transactionId
        };
        this.gtmService.pushTag(gtmtag);
        break;

      default:
        break;
    }

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
          transactionId: this.transactionId
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
  toggleMobileMenu(tag: string = '') {
    this.openMobileMenu = !this.openMobileMenu;
    this.onNavigation(tag);
  }

  toggleSolucoesMenu() {
    this.openSolucoesMenu = !this.openSolucoesMenu;
    this.onNavigation();
  }

  toggleVendasMenu() {
    this.openVendasMenu = !this.openVendasMenu;
    this.onNavigation();
  }

  toggleGestaoMenu() {
    this.openGestaoMenu = !this.openGestaoMenu;
    this.onNavigation();
  }

  toggleServicosMenu() {
    this.openServicosMenu = !this.openServicosMenu;
    this.onNavigation();
  }

}

