<div class="chatbot">
  <div class="avatar">
    <a href="https://wa.me/5511941473336" target="_blank"><img class="avatar-img" src="assets/img/icone-wpp.png"
        alt="Atendente virtual" /></a>
    <!-- <div id="balao-chat" class="chat">
      <div class="chat-message">
        <span class="container-message">
          <span>
            <p class="m-0">
              Olá! Sou a assistente virtual da azulzinha. Posso lhe ajudar com
              as suas dúvidas?
            </p>
          </span>
        </span>
      </div>
    </div> -->
  </div>
</div>