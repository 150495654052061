
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import CardJson from '../../../card.json';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-manual-maquinas',
  templateUrl: './manual-maquinas.component.html',
  styleUrls: ['./manual-maquinas.component.scss']
})
export class ManualMaquinasComponent implements OnInit {


  faq_card = CardJson.main
  title!: string
  pagina!: string
  src!: string
  minha_pagina: any
  imagem!: string
  imagemClick!: string
  faq: any
  manual: any
  lista: any
  numero: string = ''
  texto: string = ''
  public env = environment


  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0)
    const urlParams = new URLSearchParams(location.search);
    const variavel = urlParams.get('variavel')
    this.lista = document.querySelectorAll('.lista')
    let id = false
    this.faq_card.forEach((e: any) => {
   
      if (variavel === e.id) {
        id = true;
      }
    });


    if (!variavel || !id) {
      this.router.navigate(['ajuda']);
      return
    }
    this.route.queryParams.subscribe(
      (queryParams: any) => {


        this.pagina = queryParams['variavel']
      }
    )
    this.minha_pagina = this.faq_card.filter((e: any) => {
      return e.id === variavel
    })

    this.minha_pagina.map((e: any) => {
      this.title = e.title
      this.imagem = e.imagem
      this.imagemClick = e.imagemClick
      this.src = e.src
    })
    this.manual = this.minha_pagina[0].manual
    this.faq = this.minha_pagina[0].faq
  }

}
