<div class="politicaPrivacidade hide">
  <div class="col-md-10 mensagem">
    <p>
      Confira nosso Aviso de Privacidade.
      <a routerLink="/politica-de-privacidade">Clique aqui</a> para consultar e
      entender como realizamos o tratamento dos seus dados pessoais.
    </p>
    <p>
      Usamos cookies e outras tecnologias para melhorar sua experiência com
      nossos serviços e ofertar nossos produtos e serviços de acordo com seus
      interesses. <a routerLink="/aviso-de-cookies">Clique aqui</a> para saber
      mais sobre cookies ou como desabilitá-los nas configurações do seu
      navegador.
    </p>
  </div>
  <div class="col-md-2 botao">
    <button (click)="enviarCookie()" id="btn-entendi" class="btn btn-primary">
      Aceitar
    </button>
  </div>
</div>

