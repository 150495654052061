import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class RedirectComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      window.location.href = 'https://incentivolotericas.azulzinhadacaixa.com.br'  
    }, 2000);
  }
}