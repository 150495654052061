import { Component, OnInit } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager'

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public env = environment

  constructor(private gtmService: GoogleTagManagerService) { }

  ngOnInit(): void {
    
  }

  onClickFaleConosco() {
    const gtmtag = {
      event: 'button-click',
      data: 'Fale-conosco',
      transactionId: 332950684
    };
    this.gtmService.pushTag(gtmtag);
  }

}
