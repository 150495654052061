import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit{

  constructor() { }
  
  enviarCookie(){
    localStorage.setItem('cookie', 'accept')
    document.querySelector('.politicaPrivacidade')?.classList.add('hide')
  }
  

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const cookie = localStorage.getItem('cookie')
    if(cookie == null){
      document.querySelector('.politicaPrivacidade')?.classList.remove('hide')
   }
  }
}
