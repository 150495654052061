
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqComponent } from './pages/ajuda/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { PaginaNotFoundComponent } from './pages/pagina-not-found/pagina-not-found.component';
import { ManualMaquinasComponent } from './pages/faq-categorias/manual-maquinas/manual-maquinas.component';
import { RedirectComponent } from './app-redirect-component';


const routes: Routes = [

  { path: 'vendas-online', loadChildren: () => import('./pages/vendas-online/vendas-online.module').then(m => m.VendasOnlineModule) },
  { path: 'ajuda', loadChildren: () => import('./pages/ajuda/faq.module').then(m => m.FaqModule) },
  { path: 'antecipacao-de-vendas', loadChildren: () => import('./pages/antecipacao/antecipacao.module').then(m => m.AntecipacaoModule) },
  { path: 'app', loadChildren: () => import('./pages/aplicativo-caixa/aplicativo-caixa.module').then(m => m.AplicativoCaixaModule) },
  { path: 'aviso-de-cookies', loadChildren: () => import('./pages/aviso-cookies/aviso-cookies.module').then(m => m.AvisoCookiesModule) },
  { path: 'azulzinha-aproxima', loadChildren: () => import('./pages/azulzinha-aproxima/azulzinha-aproxima.module').then(m => m.AzulzinhaAproximaModule) },
  { path: 'maquinas-e-taxas', loadChildren: () => import('./pages/bacen/bacen.module').then(m => m.BacenModule) },
  { path: 'bandeiras-vouchers', loadChildren: () => import('./pages/bandeiras-vouchers/bandeiras-vouchers.module').then(m => m.BandeirasVouchersModule) },
  { path: 'azulzinha-caixa-tem', loadChildren: () => import('./pages/caixa-tem/caixa-tem.module').then(m => m.CaixaTemModule) },
  { path: 'azulzinha-caixa-tem/:title', loadChildren: () => import('./pages/caixa-tem/caixa-tem.module').then(m => m.CaixaTemModule) },
  { path: 'gestao', loadChildren: () => import('./pages/gestao/gestao.module').then(m => m.GestaoModule) },
  { path: 'link-pagamento', loadChildren: () => import('./pages/link-pagamento/link-pagamento.module').then(m => m.LinkPagamentoModule) },
  { path: 'lotericas', loadChildren: () => import('./pages/lotericos/lotericos.module').then(m => m.LotericosModule) },
  { path: 'maquininha-de-cartao-azulzinha-da-caixa', loadChildren: () => import('./pages/lp-position/lp-position.module').then(m => m.LpPositionModule) },
  { path: 'oferta-especial-azulzinha-da-caixa', loadChildren: () => import('./pages/lucros-altos/lucros-altos.module').then(m => m.LucrosAltosModule) },
  { path: 'maquininhas', loadChildren: () => import('./pages/maquininha/maquininha.module').then(m => m.MaquininhaModule) },
  { path: 'pagamento-parcial', loadChildren: () => import('./pages/pagamento-parcial/pagamento-parcial.module').then(m => m.PagamentoParcialModule) },
  { path: 'pix-azulzinha', loadChildren: () => import('./pages/pix/pix.module').then(m => m.PixModule) },
  { path: 'politica-de-privacidade', loadChildren: () => import('./pages/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule) },
  { path: 'portal', loadChildren: () => import('./pages/portal-novo/portal-novo.module').then(m => m.PortalNovoModule) },
  { path: 'maquininhas/pro', loadChildren: () => import('./pages/pos/pos.module').then(m => m.PosModule) },
  { path: 'bandeiras-vouchers/qr-code', loadChildren: () => import('./pages/qr-code/qr-code.module').then(m => m.QrCodeModule) },
  { path: 'recarga-de-celulares', loadChildren: () => import('./pages/recarga/recarga.module').then(m => m.RecargaModule) },
  { path: 'azulzinhasmart', loadChildren: () => import('./pages/smart-pos/smart-pos.module').then(m => m.SmartPosModule) },
  { path: 'peca-azulzinha', loadChildren: () => import('./pages/solicitar-proposta/solicitar-proposta.module').then(m => m.SolicitarPropostaModule) },
  { path: 'obrigado', loadChildren: () => import('./pages/thank-you-page/thank-you-page.module').then(m => m.ThankYouPageModule) },
  { path: 'maquininhas/tef', loadChildren: () => import('./pages/tef/tef.module').then(m => m.TefModule) },
  { path: 'termo-lgpd', loadChildren: () => import('./pages/termo-lgpd/termo-lgpd.module').then(m => m.TermoLgpdModule) },
  { path: 'pagamentos-whatsapp', loadChildren: () => import('./pages/whatsapp-pay/whatsapp-pay.module').then(m => m.WhatsAppPayModule) },
  { path: 'cliente-loterica', loadChildren: () => import('./pages/loterica/loterica.module').then(m => m.LotericaModule) },
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/:id', loadChildren: () => import('./pages/post-detail/post-detail.module').then(m => m.PostDetailModule) },

  { path: 'incentivolotericas', component: RedirectComponent },
  { path: 'manual', component: ManualMaquinasComponent },
  { path: '**', component: PaginaNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
