<div class="container-not">
  <div class="row">
    <div class="img">
      <img
        class="mx-100"
        src="/assets/img/tomada.png"
        alt="Imagem 404-not-found "
      />
    </div>
    <div class="content-texto">
      <div class="caixa-texto">
        <h4 class="texto">Sentimos muito</h4>
        <div>
          <p style="font-size: 20px">
            A página que tentou acessar<br>não está disponível
          </p>
          <a class="btn btn-primary btn-big" routerLink="/">Ir para a Home</a>
        </div>
      </div>
    </div>
    <div class="content-404 text-center">
      <img
        class="imagem-404 img-fluid"
        [lazyLoad]="'/assets/img/404.png'"
        alt="Imagem 404-not-found "
      />
    </div>
  </div>
</div>
